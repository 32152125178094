import {
  AppstoreAddOutlined, CodeFilled, FolderOutlined, NodeIndexOutlined, SettingOutlined, TeamOutlined, ThunderboltOutlined, UserOutlined
} from '@ant-design/icons'
import { Button, Tooltip } from 'antd'
import { Loading, NotFound } from 'atoms'
import { useAuth } from 'auth-context'
import BadgeCount from 'components/BadgeCount/BadgeCount'
import { OrganizationContext, TeamList } from 'components/Organization'
import Tabs from 'components/Tabs'
import React, { useEffect, useMemo, useState } from 'react'

import PluginList from './PluginsList'
import ProjectList from './ProjectList'
import RecipeList from './RecipeList'
import Settings from './Settings'
import Subscription from './Subscription'

import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import ProfileSummary from 'atoms/ProfileSummary'
import { AppsList } from 'components/Apps'
import { useAccount } from 'hooks'
import { ResourceType, useRoles } from 'hooks/useRoles'
import { useHistory, useLocation } from 'react-router-dom'
import { ProfileTemplate } from 'templates'
import MembersTab from './Members'


const devSearch = [
  '?tab=apps',
  '?tab=plugins',
  '?tab=recipes'
]

export const ProfilePage: React.FC = () => {
  const [enableDevMode, setEnableDevMode] = useState(false)
  const history = useHistory()
  const location = useLocation()
  const { client } = useAuth()
  const screens = useBreakpoint()
  const {
    loading,
    canWrite: isOwner,
    canRead: isMember,
    account,
    fetchAccount,
    updateAccount,
    deleteAccount,
    organization
  } = useAccount()
  const { canAdmin, canRead } = useRoles()

  const [childRefresh, setChildRefresh] = useState(false)

  /**
   * Auto enable dev mode if navigation by link
   */
  useEffect(() => {
    if (enableDevMode) return

    if (devSearch.includes(location.search)) {
      setEnableDevMode(true)
    }
  }, [location.search, setEnableDevMode, enableDevMode])

  const handleOnClick = () => {
    setEnableDevMode((prev: boolean) => {
      const val = !prev
      // Go to project if tab is apps/plugins/recipes
      if (devSearch.includes(location.search) && !val) {
        history.push('?tab=projects')
      }
      return val
    })
  }

  const tabs = useMemo(() => {
    if (!account) return undefined

    const getAppsCount = (accountName: string) =>
      client.applications.listApplications({ owner: [accountName] })
        .then(({ data }) => data.total_count)

    const getProjectsCount = (accountName: string) =>
      client.projects.listProjects({ owner: [accountName] })
        .then(({ data }) => data.total_count)

    const getRecipesCount = (accountName: string) =>
      client.recipes.listRecipes({ owner: [accountName] })
        .then(({ data }) => data.total_count)

    const getPluginsCount = (accountName: string) =>
      client.plugins.listPlugins({ owner: [accountName] })
        .then(({ data }) => data.total_count)

    const devTabsArray = [
      {
        label: 'Apps',
        icon: <AppstoreAddOutlined />,
        content: account ? <AppsList owner={[account.name]} /> : <Loading />,
        badge: <BadgeCount accountName={account?.name} countFetch={getAppsCount} />
      },
      {
        label: 'Recipes',
        icon: <NodeIndexOutlined />,
        content: <RecipeList owner={account?.name} />,
        badge: <BadgeCount accountName={account?.name} countFetch={getRecipesCount} />
      },
      {
        label: 'Plugins',
        icon: <AppstoreAddOutlined />,
        content: <PluginList owner={account?.name} />,
        badge: <BadgeCount accountName={account?.name} countFetch={getPluginsCount} />
      }
    ]

    const tabsArray = [
      {
        label: 'Projects',
        icon: <FolderOutlined />,
        content: <ProjectList canWrite={canAdmin(ResourceType.Project)} owner={account?.name} />,
        badge: <BadgeCount accountName={account?.name} countFetch={getProjectsCount} />
      }
    ]

    if (typeof organization !== 'undefined' && account) {
      if (isMember || isOwner) {
        tabsArray.push(
          {
            label: 'Members',
            icon: <UserOutlined />,
            content: <MembersTab org={organization} />,
            badge: <BadgeCount accountName={account?.name} count={organization.member_count} />
          }
        )
      }

      if (canAdmin(ResourceType.OrganizationTeam)) {
        tabsArray.push(
          {
            label: 'Teams',
            icon: <TeamOutlined />,
            content: <TeamList org={organization} />,
            badge: <BadgeCount accountName={account?.name} count={organization.team_count} />
          }
        )
      }
    }

    // This is a bit of a hack as an account owner will return true regardless of
    // whether the account is of type organisation or user
    if (canAdmin(ResourceType.Organization) && account) {
      tabsArray.push({
        label: 'Settings',
        icon: <SettingOutlined />,
        content: <Settings
          account={account}
          updateAccount={updateAccount}
          deleteAccount={deleteAccount}
          refetch={fetchAccount} />,
        badge: <></>
      })
    }

    if ((canAdmin(ResourceType.Subscription)) && account) {
      tabsArray.push({
        label: 'Subscription',
        icon: <ThunderboltOutlined />,
        content: <Subscription account={account} />,
        badge: <></>
      })
    }

    return enableDevMode
      ? [...tabsArray, ...devTabsArray]
      : tabsArray
  }, [
    account,
    canAdmin,
    client.applications,
    client.plugins,
    client.projects,
    client.recipes,
    fetchAccount,
    organization,
    updateAccount,
    enableDevMode,
    deleteAccount,
    isMember,
    isOwner,
  ])

  if (loading) {
    return <Loading />
  }

  if (!loading && typeof account === 'undefined') {
    return <NotFound />
  }

  return (
    <OrganizationContext.Provider value={{
      refresh: childRefresh,
      setRefresh: setChildRefresh
    }}>
      <ProfileTemplate
        leftColumn={
          <div
            style={(screens.xs || !screens.lg)
              ? {
                height: 'fit-content',
                padding: 12,
                backgroundColor: '#fff',
                borderRadius: 5,
                marginTop: 34
              }
              : {
                height: 'fit-content',
                top: 24,
                alignSelf: 'flex-start',
                position: 'sticky',
                boxShadow: '0 3px 6px rgba(140,149,159,0.15)',
                maxWidth: 500,
                padding: 12,
                backgroundColor: '#fff',
                borderRadius: 5,
                marginTop: 34
              }}
          >
            <ProfileSummary
              account={account}
              refresh={childRefresh}
              isAdmin={isOwner}
              isMember={isMember} />
          </div>
        }
        tabs={
          tabs ? <Tabs tabs={tabs} extraContent={
            {
              right: <Tooltip
                placement='left'
                title='Dev features'>
                <Button
                  type='text'
                  size='small'
                  style={{ backgroundColor: 'rgba(0,0,0,0)' }}
                  onClick={handleOnClick}
                >
                  <CodeFilled style={{ fontSize: 16 }} />
                </Button>
              </Tooltip>
            }
          } /> : undefined
        }
      />
    </OrganizationContext.Provider>
  )
}

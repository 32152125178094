import React, { useCallback } from 'react'

import { AxiosResponse } from 'axios'

import { LogoutOutlined } from '@ant-design/icons'
import { Avatar, Button, Divider, List, notification, Space, Tooltip, Typography } from 'antd'

import { AccountPublic, ActivationList, LicensePoolPublic } from '@pollination-solutions/pollination-sdk'
import dayjs from 'dayjs'

import { Empty } from 'atoms'

export interface LicensePoolActivationsListProps {
  pool: LicensePoolPublic
  cancelActivation: (poolId: string, activationId: string) => Promise<AxiosResponse<void>>
  activations?: ActivationList
  mutateActivations: any
}

export const LicensePoolActivationsList: React.FunctionComponent<LicensePoolActivationsListProps> = ({ pool, activations, cancelActivation, mutateActivations }) => {

  const handleCancelActivation = useCallback((activationId: string, activeUser?: AccountPublic) => {
    if (!activeUser) return

    cancelActivation(pool.id, activationId)
      .catch((error) => {
        mutateActivations()
        notification.error({
          message: error.data.details
        })
      })
      .then(() => {
        mutateActivations()
        notification.success({
          message: `Successfully deactivated ${activeUser.name}'s floating license. They still have access to the license pool.`
        })
      })
  }, [cancelActivation, mutateActivations, pool.id])

  return (
    <List
      style={{ width: '100%' }}
      dataSource={activations?.resources.filter(a => a.user)}
      header='Members currently using licenses from this license pool.'
      locale={{
        emptyText: <Empty
          description='No members are using a license from this pool right now.'
        />
      }}
      renderItem={(activation) =>
        <List.Item actions={[
          pool.permissions.admin &&
          <Tooltip title='Remove Access' key={`remove-${activation.id}`}>
            <Button shape='circle'
              onClick={() => handleCancelActivation(activation.id, activation.user)}
              danger
              ghost
            >
              <LogoutOutlined style={{ display: 'flex', justifyContent: 'center' }} />
            </Button>
          </Tooltip>
        ]}>
          <List.Item.Meta
            avatar={<Avatar src={activation.user?.picture_url} />}
            title={activation.user?.display_name}
            description={<Space split={<Divider type='vertical' />}>
              <Typography.Text type='secondary'>
                On: {activation.hostname}
              </Typography.Text>
              <Typography.Text type='secondary'>
                Last License Sync: {dayjs(activation.last_synced_at).format('DD MM \'YY HH:mm:ss')}.
              </Typography.Text>
            </Space>}
          />
        </List.Item>
      }
    />
  )
}
import { Organization } from '@pollination-solutions/pollination-sdk'
import { Col, Collapse, Input, Row, Select } from 'antd'
import { useAuth } from 'auth-context'
import useOrganizationMembers from 'hooks/useOrganizationMembers'
import { ResourceType, useRoles } from 'hooks/useRoles'
import AddOrganizationMember from 'molecules/AddOrganizationMember'
import OrganizationMemberList from 'organisms/OrganizationMemberList'
import React, { useCallback, useMemo } from 'react'

interface MembersTabProps {
  org: Organization
}

const MembersTab: React.FC<MembersTabProps> = (props) => {
  const { client } = useAuth()
  const { canAdmin, canWrite } = useRoles()
  const {
    membersWithRoles, roles,
    addMember, removeMember,
    upsertMemberRole, removeMemberRole,
    loading
  } = useOrganizationMembers({ organizationName: props.org.account_name as string })

  const [search, setSearch] = React.useState<string | undefined>(undefined)
  const [roleFilter, setRoleFilter] = React.useState<string | undefined>(undefined)

  const searchUsers = (searchTerm: string) => {
    return client.users.listUsers({ search: searchTerm }).then(({ data }) => {
      return data.resources.filter((user) => !membersWithRoles.map((member) => member.user.username).includes(user.username))
    })
  }

  const filteredMembers = useMemo(() => {
    let filteredMembers = membersWithRoles
    if (search) {
      filteredMembers = filteredMembers.filter((member) => member.user.username.includes(search))
    }
    if (roleFilter) {
      filteredMembers = filteredMembers.filter((member) => member.roles.map((role) => role.name).includes(roleFilter))
    }
    return filteredMembers
  }, [membersWithRoles, search, roleFilter])

  const onRoleChange = useCallback(async (username: string, roles: string[]) => {
    const currentMemberRoles = membersWithRoles.find((member) => member.user.username === username)?.roles.map(r => r.name) || []
    const rolesToAdd = roles.filter((role) => !currentMemberRoles.includes(role))
    const rolesToRemove = currentMemberRoles.filter((role) => !roles.includes(role))
    await Promise.all([
      upsertMemberRole(username, rolesToAdd),
      Promise.all(rolesToRemove.map((role) => removeMemberRole(username, role)))
    ])
  }, [membersWithRoles, upsertMemberRole, removeMemberRole])


  return (
    <Row gutter={[16, 16]} justify='space-between'>
      <Col span={16}>
        <Row gutter={[8, 8]}>
          <Col xs={24} sm={24} md={8}>
            <Input.Search allowClear placeholder='Search members' onChange={(e) => setSearch(e.target.value)} />
          </Col>
          <Col xs={24} sm={24} md={8}>
            <Select placeholder='Filter by role' allowClear onChange={(value) => setRoleFilter(value)}>
              {roles.map((role) => (
                <Select.Option key={role.name} value={role.name}>{role.display_name}</Select.Option>
              ))}
            </Select>
          </Col>
        </Row>
      </Col>

      <Col>
        <AddOrganizationMember searchUsers={searchUsers} addUser={addMember} disabled={!canWrite(ResourceType.OrganizationMember)} />
      </Col>
      <Col span={24}>
        <Collapse >
          <Collapse.Panel header='Role Descriptions - Expand this section to understand what each role means' key='1'>
            <Row gutter={[16, 16]}>
              {roles.map((role) => (
                <Col key={role.name} span={8}>
                  <h4>{role.display_name}</h4>
                  <p>{role.description}</p>
                </Col>
              ))}
            </Row>
          </Collapse.Panel>
        </Collapse>
      </Col>
      <Col span={24}>
        <OrganizationMemberList
          loading={loading}
          members={filteredMembers}
          availableRoles={roles}
          onRoleChange={onRoleChange}
          onDelete={removeMember}
          canChangeRole={canAdmin(ResourceType.OrganizationMember)}
          canDelete={canWrite(ResourceType.OrganizationMember)}
        />
      </Col>
    </Row>
  )
}

export default MembersTab
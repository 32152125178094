import { Avatar } from 'antd'
import { Dropdown, OptionGroup } from 'pollination-react-io'
import React, { useEffect } from 'react'

import {
  ApiOutlined,
  AppstoreAddOutlined,
  KeyOutlined,
  LogoutOutlined,
  MenuOutlined,
  NodeIndexOutlined,
  ProjectOutlined,
  SettingOutlined,
  TeamOutlined,
  ThunderboltOutlined, UsergroupAddOutlined, UserOutlined
} from '@ant-design/icons'
import { useAccountInfo } from 'hooks/useAccountInfo'
import { ResourceType, useRoles } from 'hooks/useRoles'
import { useHistory } from 'react-router-dom'
import styles from './Header.module.css'

interface AccountOptionsProps {
  disabled: boolean
  logout: () => void
}

export const AccountOptions = ({
  disabled,
  logout
}: AccountOptionsProps): React.ReactElement<AccountOptionsProps> => {
  const history = useHistory()

  const { accountInfo, accountOptions, setAccountOptions } = useAccountInfo()
  const { canRead, canAdmin } = useRoles()

  useEffect(() => {
    if (!setAccountOptions) return
    if (!accountInfo?.name) return setAccountOptions([] as OptionGroup[])

    const options = {
      account: {
        type: 'node',
        content: <h3>{`👋 ${accountInfo?.name}!`}</h3>,
        id: `${accountInfo?.name}-label`,
      },
      projects: {
        type: 'button',
        label: 'Your Projects',
        id: 'projects',
        icon: <ProjectOutlined />,
        onSelect: () => history
          .push(`/${accountInfo?.name}?tab=projects`),
      },
      apps: {
        type: 'button',
        label: 'Your Apps',
        id: 'apps',
        icon: <AppstoreAddOutlined />,
        onSelect: () => history
          .push(`/${accountInfo?.name}?tab=apps`),
      },
      recipes: {
        type: 'button',
        label: 'Your Recipes',
        id: 'recipes',
        icon: <NodeIndexOutlined />,
        onSelect: () => history
          .push(`/${accountInfo?.name}?tab=recipes`),
      },
      plugins: {
        type: 'button',
        label: 'Your Plugins',
        id: 'plugins',
        icon: <AppstoreAddOutlined />,
        onSelect: () => history
          .push(`/${accountInfo?.name}?tab=plugins`),
      },
      members: {
        type: 'button',
        label: 'Your Members',
        id: 'members',
        icon: <UserOutlined />,
        onSelect: () => history
          .push(`/${accountInfo?.name}?tab=members`),
      },
      teams: {
        type: 'button',
        label: 'Your Teams',
        id: 'teams',
        icon: <TeamOutlined />,
        onSelect: () => history
          .push(`/${accountInfo?.name}?tab=teams`),
      },
      settings: {
        type: 'button',
        label: 'Your Settings',
        id: 'settings',
        icon: <SettingOutlined />,
        onSelect: () => history
          .push(`/${accountInfo?.name}?tab=settings`),
      },
      subscriptions: {
        type: 'button',
        label: 'Your Subscription',
        id: 'subscriptions',
        icon: <ThunderboltOutlined />,
        onSelect: () => history
          .push(`/${accountInfo?.name}?tab=subscription`),
      },
      cadPlugins: {
        type: 'button',
        label: 'Download CAD Plugins',
        id: 'cad-plugins',
        icon: <ApiOutlined />,
        onSelect: () => history
          .push('/cad-plugins'),
      },
      licensePools: {
        type: 'button',
        label: 'License Pools',
        id: 'license-pools',
        icon: <KeyOutlined />,
        onSelect: () => history
          .push(`/${accountInfo?.name}/licenses`),
      },
      createOrg: {
        type: 'button',
        icon: <UsergroupAddOutlined />,
        label: 'Create an Organization',
        id: 'create-org',
        onSelect: () => history
          .push(`${accountInfo?.name}/create-org`),
      },
      logout: {
        type: 'button',
        icon: <LogoutOutlined />,
        label: 'Logout',
        id: 'logout',
        onSelect: () => logout
      }
    }
    const isOrgAccount = accountInfo.type === 'org'
    const isAccountOwner = accountInfo.owner

    const newAccountOptions = [
      {
        options: [
          options.account,
        ]
      },
      {
        options: [
          options.projects,
          isOrgAccount && canAdmin(ResourceType.Organization, accountInfo.name) && options.members,
          isOrgAccount && canAdmin(ResourceType.OrganizationTeam, accountInfo.name) && options.teams,
          isAccountOwner && options.settings,
          canRead(ResourceType.Subscription, accountInfo.name) && options.subscriptions,
        ]
      },
      {
        options: [
          options.apps,
          options.recipes,
          options.plugins
        ]
      },
      {
        options: [
          options.cadPlugins,
          options.licensePools,
        ]
      },
      !isOrgAccount && isAccountOwner && {
        options: [
          options.createOrg,
        ]
      },
      {
        options: [
          options.logout
        ]
      }
    ].filter(group => {
      return group
    }).map((group) => (
      {
        // @ts-expect-error I... don't know how to fix this type error but it works...
        options: group.options.filter((option) => option)
      }
    ))

    setAccountOptions(newAccountOptions)
  }, [accountInfo, canRead, canAdmin, history, logout, setAccountOptions])

  return (
    <Dropdown
      optionGroups={accountOptions ?? []}
      contentProps={{
        align: 'end',
        sideOffset: -8,
        style: {
          borderColor: 'var(--primary)',
          zIndex: 1000,
          lineHeight: '1.25rem',
        }
      }}
      disabled={disabled}
      trigger={
        <button className={styles['header-avatar-button']}>
          <Avatar icon={
            <MenuOutlined style={{
              color: '#333',
              fontSize: 20
            }} className={styles['header-avatar-switcher']} />
          }
            size={38}
            shape='square'
            className={styles['header-avatar-trigger']}
            style={{
              backgroundColor: 'rgba(0,0,0,0)',
              marginLeft: 2,
            }}
          />
        </button>
      }
    />)
}
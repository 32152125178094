import { DeleteOutlined } from '@ant-design/icons'
import { OrganizationMember, OrganizationRole } from '@pollination-solutions/pollination-sdk'
import { Avatar, Button, Col, List, notification, Row, Select } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'
const { Option } = Select

interface Member extends OrganizationMember {
  roles: OrganizationRole[]
}


interface OrganizationMemberListProps {
  loading?: boolean
  members: Member[]
  canChangeRole?: boolean
  canDelete?: boolean
  availableRoles: OrganizationRole[]
  onRoleChange: (username: string, roles: string[]) => Promise<void>
  onDelete: (username: string) => Promise<void>
}

const OrganizationMemberList: React.FC<OrganizationMemberListProps> = ({ loading, members, availableRoles, canChangeRole, canDelete, onRoleChange, onDelete }) => {
  const [deletingUser, setDeletingUser] = React.useState<string | null>(null)
  const [changingRole, setChangingRole] = React.useState<string | null>(null)

  const handleRoleChange = (username: string, roles: string[]) => {
    setChangingRole(username)
    onRoleChange(username, roles).then(() => {
      notification.success({
        message: `Updated roles for ${username}`,
        description: `${username} has been updated with new roles.`
      })
    }
    ).catch((e) => {
      notification.error({
        message: `Error updating roles for ${username}`,
        description: e.message
      })
    }
    ).finally(() => {
      setChangingRole(null)
    })
  }

  const handleDeleteMember = (username) => {
    setDeletingUser(username)
    onDelete(username).then(() => {
      notification.warning({
        message: `Deleted ${username}`,
        description: `${username} has been removed from the organization.`
      })
    }).catch((e) => {
      notification.error({
        message: `Error deleting ${username}`,
        description: e.message
      })
    }).finally(() => {
      setDeletingUser(null)
    })
  }

  return (
    <List
      itemLayout="horizontal"
      dataSource={members}
      loading={loading}
      renderItem={member => (
        <List.Item
          key={JSON.stringify(member)}
        >
          <Row gutter={[8, 8]} style={{ width: '100%' }} justify='space-between'>
            <Col xs={24} sm={8}>
              <Link to={`/${member.user.username}`}>
                <List.Item.Meta
                  avatar={<Avatar src={member.user.picture} />}
                  title={member.user.name}
                  description={member.user.username}
                />
              </Link>
            </Col>
            <Col xs={20} sm={12}>
              <Select
                mode="multiple"
                style={{ width: '100%' }}
                disabled={!canChangeRole}
                placeholder="Select roles"
                loading={changingRole === member.user.username}
                defaultValue={member.roles.map(role => role.name)}
                onChange={(roles) => handleRoleChange(member.user.username, roles)}
              >
                {availableRoles.map(role => (
                  <Option key={role.name} value={role.name}>
                    {role.display_name}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col>
              <Button
                key={`delete-${member.user.username}`}
                disabled={!canDelete}
                shape="circle"
                loading={deletingUser === member.user.username}
                onClick={() => handleDeleteMember(member.user.username)}
                icon={<DeleteOutlined />}
              />
            </Col>
          </Row>
        </List.Item>
      )}
    />
  )
}

export default OrganizationMemberList
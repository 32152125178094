import { Layout, notification } from 'antd'
import React, { useEffect } from 'react'

import { Footer, Header } from './atoms'

import Routes from './routes'

import { BrowserRouter, Route } from 'react-router-dom'

import { AuthProvider as PollinationAuthProvider } from './auth-context'

import FlagProvider from 'hooks/useFlags'
import { QueryParamProvider } from 'use-query-params'

import { getAnalytics } from 'firebase/analytics'
import { getAuth } from 'firebase/auth'
import { getRemoteConfig } from 'firebase/remote-config'
import { AccountInfoProvider } from 'hooks/useAccountInfo'
import { RolesProvider } from 'hooks/useRoles'
import { AnalyticsProvider, AuthProvider, RemoteConfigProvider, useFirebaseApp } from 'reactfire'
import './App.css'

const PADDLE_ENV = process.env.REACT_APP_PROJECT_ID && !process.env.REACT_APP_PROJECT_ID.includes('staging') ? 'production' : 'sandbox'

// https://dev.to/christo_pr/render-dangerous-content-with-react-2j7j
const PADDLE_SCRIPT = `
<script type="text/javascript" id="paddle-script">
  const vendor = '${PADDLE_ENV}' === 'production' ? 14039 : 4631;
  Paddle.Environment.set('${PADDLE_ENV}');
  Paddle.Setup({ vendor });
</script>
`

const App: React.FC = () => {

  useEffect(() => {
    const parsedHTML = document.createRange().createContextualFragment(PADDLE_SCRIPT)
    document.body.appendChild(parsedHTML)

    return (() => {
      const script = document.getElementById('paddle-script')
      if (!script) return
      script.remove()
    })
  }, [])

  notification.config({
    placement: 'bottomLeft',
    maxCount: 1
  })

  const firebaseAuthInstance = getAuth(useFirebaseApp())
  const firebaseAnalyticsInstance = getAnalytics(useFirebaseApp())
  const firebaseRemoteConfigInstance = getRemoteConfig(useFirebaseApp())

  return (
    <>
      <AuthProvider sdk={firebaseAuthInstance}>
        <AnalyticsProvider sdk={firebaseAnalyticsInstance}>
          <RemoteConfigProvider sdk={firebaseRemoteConfigInstance}>
            <PollinationAuthProvider>
              <FlagProvider>
                <BrowserRouter>
                  <QueryParamProvider ReactRouterRoute={Route}>
                    <AccountInfoProvider>
                      <RolesProvider>
                        <Layout style={{ background: 'radial-gradient(circle, rgb(255, 250, 238) 55%, rgb(255, 252, 249) 88%)' }}>
                          <Header />
                          <Layout.Content style={{ margin: 16, minHeight: '100vh' }}>
                            <Routes />
                          </Layout.Content>
                          <Footer />
                        </Layout>
                      </RolesProvider>
                    </AccountInfoProvider>
                  </QueryParamProvider>
                </BrowserRouter>
              </FlagProvider>
            </PollinationAuthProvider>
          </RemoteConfigProvider>
        </AnalyticsProvider>
      </AuthProvider>
    </>
  )
}

export default App

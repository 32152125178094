import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { PlusCircleOutlined, TeamOutlined } from '@ant-design/icons'
import { Avatar, Button, Col, Divider, Form, Input, List, Modal, notification, Select, Space, Typography } from 'antd'
import debounce from 'lodash.debounce'

import { Permission, Team } from '@pollination-solutions/pollination-sdk'

import { InputLabel } from 'atoms'
import DeleteButton from 'atoms/DeleteButton'
import SelectPermission from 'atoms/SelectPermission'
import { TeamPermission } from 'hooks/useLicenseTeams'

interface CreateTeamPayload {
  name: string
  description?: string
}

interface SearchTeamsProps {
  searchTeams: (search?: string) => Promise<Team[]>
  selectTeam: (teams: Team) => void
  createTeam?: (createTeam: CreateTeamPayload) => Promise<Team | undefined>
}

const SearchTeams: React.FunctionComponent<SearchTeamsProps> = ({ searchTeams, selectTeam, createTeam }) => {

  const [search, setSearch] = useState<string>('')
  const [teams, setTeams] = useState<Team[]>([])
  const [selectedTeam, setSelectedTeam] = useState<Team | undefined>()

  const [createTeamModalVisible, setCreateTeamModalVisible] = useState<boolean>(false)
  const [createTeamLoading, setCreateTeamLoading] = useState<boolean>(false)

  const debounceSearch = useMemo(() => debounce((search: string) => setSearch(search), 200), [])

  const createAndAddTeam = useCallback(async (payload: CreateTeamPayload) => {
    if (!createTeam) return

    setCreateTeamLoading(true)
    createTeam(payload).then((team) => {
      setSelectedTeam(team)
    }).finally(() => {
      setCreateTeamModalVisible(false)
      setCreateTeamLoading(false)
    })
  }, [createTeam, setCreateTeamModalVisible, setCreateTeamLoading, setSelectedTeam])

  useEffect(() => {
    searchTeams(search).then((teams) => setTeams(teams))
      .catch(error => {
        notification.error({
          message: error.data.details
        })
      })
  }, [search, searchTeams, setTeams])

  useEffect(() => {
    if (selectedTeam) {
      selectTeam(selectedTeam)
    }
  }, [selectedTeam, selectTeam])

  return (
    <>
      <Select
        style={{ width: 200 }}
        showSearch
        showArrow
        placeholder='Search Teams'
        onSearch={debounceSearch}
        value={selectedTeam?.slug}
        onSelect={(value) => {
          const team = teams.find(team => team.slug === value)
          if (team) {
            setSelectedTeam(team)
          }
        }}
      >
        <Select.Option key='create-new-team' value='create-new-team'>

          <Button type='link' value='create-new-team' onClick={() => setCreateTeamModalVisible(true)}>
            <PlusCircleOutlined style={{ color: '#1890ff' }} /> New Team
          </Button>
        </Select.Option>
        {
          teams.map(team => (
            <Select.Option key={team.name} value={team.slug}>
              {team.name}
            </Select.Option>
          ))
        }
      </Select>
      <Modal
        title='Create New Team'
        open={createTeamModalVisible}
        onCancel={() => setCreateTeamModalVisible(false)}
        footer={null}
      >
        <Form
          layout="vertical"
          name="mainForm"
          onFinish={createAndAddTeam}
          size='middle'
          initialValues={{
            name: search
          }}
        >
          <Form.Item
            name="name" label="Name" rules={[{ required: true }]}
          >
            <Input placeholder="A name for the team" />
          </Form.Item>
          <Form.Item
            name="description" label="Description"
          >
            <Input.TextArea placeholder="A description for the team" />
          </Form.Item>
          <Form.Item>
            <Button type='primary' htmlType='submit' loading={createTeamLoading}>Create Team</Button>
          </Form.Item>
        </Form>
      </Modal>
    </>

  )
}


export interface LicensePoolTeamAccessListProps {
  loading: boolean,
  teamPermissions: TeamPermission[]
  canAdd: boolean
  permissionOptions: Permission[]
  searchTeams: (search?: string) => Promise<Team[]>
  upsertTeamPermission: (team: Team, permission: Permission) => Promise<void>
  removeTeamPermission: (team: Team) => Promise<void>
  createTeam?: (createTeam: CreateTeamPayload) => Promise<Team | undefined>
}

export const ResourceTeamAccessList: React.FunctionComponent<LicensePoolTeamAccessListProps> = ({
  loading,
  teamPermissions,
  canAdd,
  permissionOptions,
  searchTeams,
  upsertTeamPermission,
  removeTeamPermission,
  createTeam,
}) => {

  const [teamToAdd, setTeamToAdd] = useState<Team | undefined>()
  const [permissionToAdd, setPermissionToAdd] = useState<Permission>(permissionOptions[0])
  const [upsertPermissionLoading, setUpsertPermissionLoading] = useState<boolean>(false)

  const upsertPermission = useCallback(() => {
    if (!teamToAdd) return
    setUpsertPermissionLoading(true)
    upsertTeamPermission(teamToAdd, permissionToAdd)
      .then(() => {
        setTeamToAdd(undefined)
      })
      .catch(error => {
        notification.error({
          message: error.data.details
        })
      })
      .finally(() => setUpsertPermissionLoading(false))
  }, [teamToAdd, permissionToAdd, upsertTeamPermission])

  const removePermission = useCallback((team: Team) => {
    return removeTeamPermission(team)
      .then(() => {
        setTeamToAdd(undefined)
      })
      .catch(error => {
        notification.error({
          message: error.data.details
        })
      })
  }, [removeTeamPermission])

  return (
    <List
      style={{ width: '100%' }}
      loading={loading}
      dataSource={teamPermissions}
      header={
        <Col span={24}>
          <Space style={{ width: '100%' }} direction='vertical'>
            <Typography.Text>Teams who have access.</Typography.Text>
          </Space>
        </Col>
      }
      footer={
        canAdd &&
        <InputLabel label='Add Teams' >
          <Space align='center'>
            <Button icon={<TeamOutlined />} shape='circle'
              disabled={!teamToAdd}
              loading={upsertPermissionLoading}
              type='primary'
              onClick={upsertPermission} />
            <Divider type='vertical' />
            <SearchTeams searchTeams={loading ? (search?: string) => (Promise.resolve([] as Team[])) : searchTeams} selectTeam={setTeamToAdd} createTeam={createTeam} />
            {permissionOptions.length > 1 &&
              <SelectPermission permissionOptions={permissionOptions} value={permissionToAdd} onChange={(value) => Promise.resolve(setPermissionToAdd(value))} />
            }
          </Space>
        </InputLabel>
      }
      renderItem={(teamPermission) =>
        <>
          <List.Item actions={[
            permissionOptions.length > 1 ? <SelectPermission key={teamPermission.team.id} permissionOptions={permissionOptions} value={teamPermission.permission} onChange={(value) => upsertTeamPermission(teamPermission.team, value)} /> : <></>,
            <Button type='link' key={teamPermission.team.id} href={teamPermission.team.link}>Edit</Button>,
            <DeleteButton key={teamPermission.team.id} title={'Remove Access'} onClick={() => removePermission(teamPermission.team)} />
          ]}>
            <List.Item.Meta
              title={teamPermission.team.name}
              description={teamPermission.team.description}
              avatar={<Avatar.Group maxCount={3}>
                {teamPermission.team.members.map(member => (
                  <Avatar key={member.user.username} src={member.user.picture} />
                ))}
              </Avatar.Group>}
            />
          </List.Item>
        </>
      }
    />
  )
}

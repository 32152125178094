import { Permission } from '@pollination-solutions/pollination-sdk'
import { Select } from 'antd'
import React, { useState } from 'react'

interface SelectPermissionProps {
  permissionOptions: Permission[],
  value: Permission,
  onChange: (value: Permission) => Promise<void>
}

const SelectPermission: React.FunctionComponent<SelectPermissionProps> = ({ permissionOptions, value, onChange }) => {
  const [loading, setLoading] = useState<boolean>(false)

  return (
    <Select
      loading={loading}
      style={{ width: 120 }}
      value={value}
      onChange={(value) => {
        setLoading(true)
        onChange(value).finally(() => setLoading(false))
      }}
    >
      {permissionOptions.map(permission => (
        <Select.Option key={permission} value={permission}>
          {permission}
        </Select.Option>
      ))}
    </Select>
  )
}

export default SelectPermission
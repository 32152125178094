import {
  AppstoreAddOutlined, ClusterOutlined,
  PlusCircleOutlined, ProjectOutlined, TeamOutlined
} from '@ant-design/icons'
import React, { useEffect } from 'react'

import { Dropdown, OptionGroup } from 'pollination-react-io'

import { Avatar } from 'antd'
import { useAccountInfo } from 'hooks/useAccountInfo'
import { ResourceType, useRoles } from 'hooks/useRoles'
import { useHistory } from 'react-router-dom'
import styles from './Header.module.css'


interface NewOptionsProps {
  disabled: boolean
}

export const NewOptions = ({
  disabled
}): React.ReactElement<NewOptionsProps> => {
  const history = useHistory()

  const { accountInfo, setNewOptions, newOptions } = useAccountInfo()
  const { canRead, canAdmin } = useRoles()

  useEffect(() => {
    if (!setNewOptions) return
    if (!accountInfo?.name) return setNewOptions([] as OptionGroup[])

    const options = {
      study: {
        type: 'button',
        label: 'New Study',
        id: 'new-job',
        icon: <ClusterOutlined />,
        onSelect: () => history.push(`/${accountInfo?.name}/new/study`)
      },
      project: {
        type: 'button',
        label: 'New Project',
        id: 'new-project',
        icon: <ProjectOutlined />,
        onSelect: () => history.push(`/${accountInfo?.name}/new/project`)
      },
      app: {
        type: 'button',
        label: 'New App',
        id: 'new-app',
        icon: <AppstoreAddOutlined />,
        onSelect: () => history.push(`/${accountInfo?.name}/new/application`)
      },
      team: {
        type: 'button',
        label: 'New Team',
        id: 'new-team',
        icon: <TeamOutlined />,
        onSelect: () => history.push(`/${accountInfo?.name}/new/team`)
      }
    }

    const isOrgAccount = accountInfo.type === 'org'

    const optionsList = [
      {
        options: [
          canAdmin(ResourceType.Project, accountInfo.name) && options.project,
          options.study,
          canAdmin(ResourceType.Application, accountInfo.name) && options.app,
        ]
      }
    ].map((group) => (
      {
        options: group.options.filter((option) => option)
      }
    ))

    if (isOrgAccount && canAdmin(ResourceType.OrganizationTeam)) {
      optionsList.push({
        options: [options.team]
      })
    }

    setNewOptions(optionsList as OptionGroup[])
  }, [accountInfo, canRead, canAdmin, history, setNewOptions])

  return (
    <Dropdown
      disabled={disabled}
      trigger={
        <button className={styles['header-avatar-button']}>
          <Avatar icon={<PlusCircleOutlined style={{
            color: '#333',
            fontSize: 20
          }} />}
            size={30}
            shape='circle'
            className={styles['header-avatar-trigger']}
            style={{ backgroundColor: 'rgba(0,0,0,0)' }}
          />
        </button>
      }
      optionGroups={newOptions ?? []}
      contentProps={{
        align: 'end',
        sideOffset: -8,
        style: {
          borderColor: 'var(--primary)',
          zIndex: 1000,
          lineHeight: '1.6rem'
        }
      }}
    />)
}
import React, { useCallback, useEffect, useState } from 'react'

import { DeleteOutlined } from '@ant-design/icons'
import { OrganizationMember, TeamMember } from '@pollination-solutions/pollination-sdk'
import { Avatar, Button, Form, List, notification, Row, Select, Space, Spin, Tooltip, Typography } from 'antd'
import { useAuth } from 'auth-context'
import { Link } from 'react-router-dom'

import { AvatarLink } from 'atoms'
import { ResourceType, useRoles } from 'hooks/useRoles'
interface Props {
  owner: string
  // teamName: string
  team: string
}

const TeamMembers = (props: Props): React.ReactElement => {
  const { owner, team } = props
  const { client, user } = useAuth()

  const [loading, setLoading] = useState<boolean>(true)
  const [fetchError, setFetchError] = useState<any | undefined>()
  const [members, setMembers] = useState<TeamMember[]>([])
  const [orgMembers, setOrgMembers] = useState<OrganizationMember[]>([])

  const [form] = Form.useForm()
  const [addingMember, setAddingMember] = useState<boolean>(false)

  const [search, setSearch] = useState<string | undefined>()
  const [orgMemberOptions, setOrgMemberOptions] = useState<OrganizationMember[]>([])

  const [canCreateMember, setCanCreateMember] = useState<boolean>(false)
  const { canAdmin } = useRoles()

  let cancelDeleteMember = false

  useEffect(() => {
    const nonTeamMembers = orgMembers.filter(m => (
      !members.map(tm => tm.user.username).includes(m.user.username)
    ))
    if (!search || search === '') {
      setOrgMemberOptions(nonTeamMembers)
    } else {
      setOrgMemberOptions(nonTeamMembers.filter(m => m.user.username.includes(search)))
    }
  }, [search, orgMembers, members])

  const fetchMembers = useCallback(() => {
    setLoading(true)
    client.orgs.getOrgMembers({ name: owner })
      .then(({ data }) => setOrgMembers(data.resources))
    client.teams.getOrgTeamMembers({ orgName: owner, teamSlug: team })
      .then(({ data }) => setMembers(data.resources))
      .catch((err) => setFetchError(err))
      .finally(() => setLoading(false))
  }, [client, owner, team])


  useEffect(() => {
    fetchMembers()
  }, [owner, team, fetchMembers])

  const handleAddMember = (): void => {
    const stagedMember = form.getFieldValue('username')
    const stagedRole = form.getFieldValue('role')

    setLoading(true)
    client.teams.upsertOrgTeamMember({
      orgName: owner,
      teamSlug: team,
      username: stagedMember,
      role: stagedRole
    }).then(() => {
      fetchMembers()
      notification.success({
        message: 'Success!',
        description: `${stagedMember} is part of the team!`
      })
    }).catch((err) => {
      notification.error({
        message: err.response.data.detail
      })
      setLoading(false)
    }).finally(() => {
      form.resetFields()
      setCanCreateMember(false)
      setLoading(false)
    })
  }

  const checkValues = (changed: any, all: any) => {
    if (all.username && all.role) {
      setCanCreateMember(true)
    } else {
      setCanCreateMember(false)
    }
  }

  function confirmDelete(owner: string, team: string, member: TeamMember) {
    if (cancelDeleteMember) {
      cancelDeleteMember = false
      return
    }

    setLoading(true)

    client.teams.deleteOrgTeamMember({
      orgName: owner,
      teamSlug: team,
      username: member.user.username
    })
      .catch((err) => {
        notification.error({
          message: err.response.data.detail
        })
        setLoading(false)
      }).finally(() => setLoading(false))
  }

  function cancelDelete(e: React.MouseEvent, member: TeamMember) {
    e.stopPropagation()
    cancelDeleteMember = true
    // can't gaurantee that setMembers() called in handleDeleteMember will or won't have completed
    setMembers([...members, member].filter((val, i, arr) => arr.findIndex((v) => v.user.username === val.user.username) === i))
    notification.close(`delete-${member.user.username}`)
    setLoading(false)
  }

  const handleDeleteMember = (owner: string, team: string, member: TeamMember) => {
    notification.warning({
      key: `delete-${member.user.username}`,
      message: `Removed ${member.user.name}`,
      description: `Removed ${member.user.name} from ${team}. ${team} now has ${members.length - 1} members.`,
      btn: <Button onClick={(e) => cancelDelete(e, member)}>Undo</Button>,
      onClose: () => confirmDelete(owner, team, member)
    })

    setMembers(members.filter(m => m.user.username !== member.user.username))
  }

  return (
    loading ?
      <Spin /> :
      <>
        <Space direction="vertical" style={{ width: '100%' }}>
          <Row style={{ width: '100%', margin: '8px 0px 0px', justifyContent: addingMember ? 'space-between' : 'flex-end' }}>
            {
              addingMember ?
                <Form
                  form={form}
                  layout="inline"
                  onFinish={handleAddMember}
                  onValuesChange={checkValues}
                >
                  <Form.Item
                    name="username"
                    label="User"
                    rules={[{ required: true }]}
                  >
                    <Select
                      showSearch
                      allowClear
                      placeholder="search org members"
                      onSearch={setSearch}
                      style={{ minWidth: '300px' }}
                    >
                      {orgMemberOptions.map(m => (
                        <Select.Option key={m.user.username} value={m.user.username}>
                          <Space>
                            <Avatar size="small" src={m.user.picture} />
                            <Typography.Text>{m.user.username}</Typography.Text>
                          </Space>
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="role"
                    label="Role"
                    rules={[{ required: true }]}
                  >
                    <Select
                      allowClear
                      placeholder="select role"
                      style={{ 'width': '100px' }}
                    >
                      <Select.Option value={'member'}>Member</Select.Option>
                      <Select.Option value={'owner'}>Owner</Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item>
                    <Button htmlType="submit" disabled={!canCreateMember}>
                      Add
                    </Button>
                  </Form.Item>
                </Form> :
                <></>
            }
            {
              addingMember ?
                <Button danger onClick={() => {
                  setAddingMember(false)
                  setCanCreateMember(false)
                  form.resetFields()
                }}
                  style={{ backgroundColor: 'rgba(0,0,0,0)', marginBottom: 12 }}>
                  Cancel
                </Button> :
                <Tooltip title={!canAdmin(ResourceType.OrganizationTeam) && 'Only owners can modify the team'}>
                  <Button
                    disabled={!canAdmin(ResourceType.OrganizationTeam)}
                    type="primary"
                    onClick={() => setAddingMember(true)} style={{ marginBottom: 12 }}>Add Member</Button>
                </Tooltip>
            }
          </Row>
          <List
            itemLayout="horizontal"
            dataSource={members}
            renderItem={(member, i) => (
              <List.Item
                actions={[
                  (
                    <Select
                      disabled={!canAdmin(ResourceType.OrganizationTeam)}
                      key={`${member.user.username}-${i}`}
                      defaultValue={member.role}
                      onSelect={(value) => {
                        client.teams.upsertOrgTeamMember({
                          orgName: owner,
                          teamSlug: team,
                          username: member.user.username,
                          role: value
                        })
                          .then(() => notification.success({
                            message: 'Updated!',
                            description: `${member.user.username} is changed to ${value}`
                          }))
                          .catch((err) => notification.error({
                            message: 'Error changing role',
                            description: err.response.data.detail,
                          }))
                      }}
                      style={{ width: '100px' }}
                    >
                      <Select.Option value="member">Member</Select.Option>
                      <Select.Option value="owner">Owner</Select.Option>
                    </Select>
                  ),
                  (
                    <Button key={`${member.user.username}-${i}-button`} shape="circle" onClick={() => handleDeleteMember(owner, team, member)} style={{ backgroundColor: 'rgba(0,0,0,0)' }}>
                      <DeleteOutlined />
                    </Button>
                  )
                ]}
                style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.2)' }}
              >
                <Link to={`/${member.user.username}`} style={{ width: '30%' }}>
                  <List.Item.Meta
                    avatar={<AvatarLink src={member.user.picture} link={`/${member.user.username}`} />}
                    title={member.user.name}
                    description={member.user.username}
                  />
                </Link>
              </List.Item>
            )} />
        </Space>
      </>
  )
}

export default TeamMembers

import { UserPublic } from '@pollination-solutions/pollination-sdk'
import { Avatar, Button, Modal, notification, Select, Space } from 'antd'
import debounce from 'lodash.debounce'
import React, { useMemo, useState } from 'react'

interface AddOrganizationMemberProps {
  disabled?: boolean
  searchUsers: (searchTerm: string) => Promise<UserPublic[]>
  addUser: (username: string) => Promise<void>
}

const AddOrganizationMember: React.FC<AddOrganizationMemberProps> = ({ disabled, searchUsers, addUser }) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [users, setUsers] = useState<UserPublic[]>([])
  const [selectedUser, setSelectedUser] = useState<string | null>(null)

  const debounceSearch = useMemo(() => debounce((search: string) => {
    if (!search) {
      setUsers([])
      return
    }
    searchUsers(search).then((users) => {
      setUsers(users)
    }
    ).catch((e) => {
      notification.error({ message: 'Error searching for users', description: e.message })
    })
  }, 200), [searchUsers])


  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleOk = () => {
    if (selectedUser) {
      setLoading(true)
      addUser(selectedUser).then(() => {
        setIsModalVisible(false)
      }).catch((e) => {
        notification.error({ message: 'Error adding member', description: e.message })
      }).finally(() => {
        notification.success({ message: 'Member added', description: `${selectedUser} has been added to the organization.` })
        setLoading(false)
      })
      setIsModalVisible(false)
    }
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }


  return (
    <>
      <Button type="primary" onClick={showModal} disabled={disabled} >
        Add Member
      </Button>
      <Modal
        title="Add Organization Member"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleOk} loading={loading} disabled={selectedUser === null}>
            Add
          </Button>,
        ]}
      >
        <Select placeholder="Search for a user" onSelect={setSelectedUser} onSearch={debounceSearch} showSearch style={{ width: '100%' }} >
          {users.map((user) => (
            <Select.Option key={user.username} value={user.username}>
              <Space>
                <Avatar src={user.picture} />
                {user.name || user.username}
              </Space>
            </Select.Option>
          ))}
        </Select>
      </Modal>
    </>
  )
}

export default AddOrganizationMember
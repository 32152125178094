import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { UserOutlined } from '@ant-design/icons'
import { Button, Col, Divider, List, notification, Select, Space, Typography } from 'antd'
import debounce from 'lodash.debounce'

import { AccountPublic, Permission } from '@pollination-solutions/pollination-sdk'

import { AvatarLink, InputLabel } from 'atoms'
import DeleteButton from 'atoms/DeleteButton'
import SelectPermission from 'atoms/SelectPermission'
import { UserPermission } from 'hooks/useLicenseUsers'



interface SearchUsersProps {
  searchUsers: (search?: string) => Promise<AccountPublic[]>
  selectUser: (user: AccountPublic) => void
}

const SearchUsers: React.FunctionComponent<SearchUsersProps> = ({ searchUsers, selectUser }) => {

  const [search, setSearch] = useState<string>('')
  const [users, setUsers] = useState<AccountPublic[]>([])

  const debounceSearch = useMemo(() => debounce((search: string) => setSearch(search), 200), [])

  useEffect(() => {
    searchUsers(search).then((users) => setUsers(users))
      .catch(error => {
        notification.error({
          message: error.data.details
        })
      })
  }, [search, searchUsers, setUsers])

  return (
    <Select
      style={{ width: 200 }}
      showSearch
      showArrow
      placeholder='Search Users'
      onSearch={debounceSearch}
      onSelect={(value) => {
        const user = users.find(user => user.name === value)
        if (user) {
          selectUser(user)
        }
      }}
    >
      {
        users.map(user => (
          <Select.Option key={user.name} value={user.name}>
            {user.display_name ?? user.name}
          </Select.Option>
        ))
      }
    </Select>
  )
}


export interface LicensePoolUserListProps {
  loading: boolean,
  userPermissions: UserPermission[]
  canAdd: boolean
  permissionOptions: Permission[]
  searchUsers: (search?: string) => Promise<AccountPublic[]>
  upsertUserPermission: (user: AccountPublic, permission: Permission) => Promise<void>
  removeUserPermission: (user: AccountPublic) => Promise<void>
}

export const ResourceUserAccessList: React.FunctionComponent<LicensePoolUserListProps> = ({
  loading,
  userPermissions,
  canAdd,
  permissionOptions,
  searchUsers,
  upsertUserPermission,
  removeUserPermission,
}) => {

  const [userToAdd, setUserToAdd] = useState<AccountPublic | undefined>()
  const [permissionToAdd, setPermissionToAdd] = useState<Permission>(permissionOptions[0])
  const [upsertPermissionLoading, setUpsertPermissionLoading] = useState<boolean>(false)

  const upsertPermission = useCallback(() => {
    if (!userToAdd) return
    setUpsertPermissionLoading(true)
    upsertUserPermission(userToAdd, permissionToAdd)
      .then(() => {
        setUserToAdd(undefined)
      })
      .catch(error => {
        notification.error({
          message: error.data.details
        })
      })
      .finally(() => setUpsertPermissionLoading(false))
  }, [userToAdd, permissionToAdd, upsertUserPermission])

  const removePermission = useCallback((user: AccountPublic) => {
    return removeUserPermission(user)
      .then(() => {
        setUserToAdd(undefined)
      })
      .catch(error => {
        notification.error({
          message: error.data.details
        })
      })
  }, [removeUserPermission])

  return (
    <List
      style={{ width: '100%' }}
      loading={loading}
      dataSource={userPermissions}
      header={
        <Col span={24}>
          <Space style={{ width: '100%' }} direction='vertical'>
            <Typography.Text>Users who have access.</Typography.Text>
          </Space>
        </Col>
      }
      footer={
        canAdd &&
        <InputLabel label='Add Users' >
          <Space align='center'>
            <Button icon={<UserOutlined />} shape='circle'
              disabled={!userToAdd}
              loading={upsertPermissionLoading}
              type='primary'
              onClick={upsertPermission} />
            <Divider type='vertical' />
            <SearchUsers searchUsers={loading ? (search?: string) => (Promise.resolve([] as AccountPublic[])) : searchUsers} selectUser={setUserToAdd} />
            {permissionOptions.length > 1 &&
              <SelectPermission permissionOptions={permissionOptions} value={permissionToAdd} onChange={(value) => Promise.resolve(setPermissionToAdd(value))} />
            }
          </Space>
        </InputLabel>
      }
      renderItem={(userPermission, i) =>
        <>
          <List.Item actions={[
            permissionOptions.length > 1 ? <SelectPermission key={userPermission.user.id} permissionOptions={[Permission.Read]} value={userPermission.permission} onChange={(value) => upsertUserPermission(userPermission.user, value)} /> : <></>,
            <DeleteButton key={userPermission.user.id} title={'Remove Access'} onClick={() => removePermission(userPermission.user)} />,
          ]}>
            <List.Item.Meta
              title={userPermission.user.name}
              avatar={<AvatarLink src={userPermission.user.picture_url} link={`/${userPermission.user.name}`} />}
            >
            </List.Item.Meta>
          </List.Item>
        </>
      }
    />
  )
}

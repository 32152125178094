
import { AppstoreAddOutlined } from '@ant-design/icons'
import { AccountPublic } from '@pollination-solutions/pollination-sdk'
import { Button, Col, Grid, Row, Skeleton, Space, Tooltip, Typography } from 'antd'
import { discourseIcon } from 'atoms/Icons'
import { useAuth } from 'auth-context'
import { useAccountInfo } from 'hooks/useAccountInfo'
import { ResourceType, useRoles } from 'hooks/useRoles'
import { UsageSummaryDashboard } from 'organisms/UsageSummary'
import { Option } from 'pollination-react-io'
import React, { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { OptionList } from './parts/OptionList'
import { RecentActivities } from './parts/RecentActivities'
import { RecentTopics } from './parts/RecentTopics'

export const UserDashboard = (): React.ReactElement => {
  const { client } = useAuth()
  const { accountInfo, newOptions, accountOptions } = useAccountInfo()
  const { canRead } = useRoles()
  const history = useHistory()

  const { xs } = Grid.useBreakpoint()

  const [account, setAccount] = useState<AccountPublic>()
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    if (!accountInfo?.name) return
    setLoading(true)

    client.accounts.getAccount({
      name: accountInfo.name
    })
      .then(({ data }) => {
        setAccount(data)
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false))
  }, [accountInfo, client])

  const options = useMemo(() => {
    const newOpt = newOptions?.map(g => g.options)
      .flat().filter(o => !['new-app', 'new-team'].includes(o.id)) ?? []

    const accountOpt = accountOptions?.map(g => g.options)
      .flat().filter(o => ['projects',
        'license-pools', 'cad-plugins'].includes(o.id)) ?? []

    const appGallery = {
      type: 'button',
      label: 'App Gallery',
      id: 'app-gallery',
      icon: <AppstoreAddOutlined />,
      onSelect: () => history.push('/apps')
    } as Option

    const opts = [...newOpt.reverse(), ...accountOpt]
    opts.splice(1, 0, appGallery)

    return opts
  }, [history, newOptions, accountOptions])

  const yourSubscription = useMemo(() => {
    return accountOptions?.map(g => g.options)
      .flat().filter(o => o.id === 'subscriptions') ?? []
  }, [accountOptions])

  const yourProjects = useMemo(() => {
    return accountOptions?.map(g => g.options)
      .flat().filter(o => o.id === 'projects') ?? []
  }, [accountOptions])

  return (
    <Skeleton active loading={loading}>
      <Row gutter={[32, 32]}>
        <Col span={24}>
          <Typography.Title level={xs ? 3 : 1}>Hi {accountInfo?.display_name}!</Typography.Title>
          <OptionList loading={loading} options={options} />
        </Col>
        <Col span={24}>
          <Space size='middle' direction='horizontal'>
            <Typography.Title level={xs ? 5 : 2}>Recent Projects</Typography.Title>
            {yourProjects.length > 0 && <Tooltip title='View Projects'>
              <Button shape='circle'
                style={{ marginBottom: 6 }}
                icon={yourProjects[0].icon}
                //@ts-ignore
                onClick={() => yourProjects[0]?.onSelect(yourProjects[0])} />
            </Tooltip>}
          </Space>
          {account && <RecentActivities account={account} />}
        </Col>
        {canRead(ResourceType.Quota, accountInfo?.name) &&
          <Col span={24}>
            <Space size='middle' direction='horizontal'>
              <Typography.Title level={xs ? 5 : 2}>Your Subscriptions</Typography.Title>
              {yourSubscription.length > 0 && <Tooltip title='Edit Subscriptions'>
                <Button shape='circle'
                  style={{ marginBottom: 6 }}
                  icon={yourSubscription[0].icon}
                  //@ts-ignore
                  onClick={() => yourSubscription[0]?.onSelect(yourSubscription[0])} />
              </Tooltip>}
            </Space>
            {account && <UsageSummaryDashboard account={account} />}
          </Col>
        }
        <Col span={24}>
          <Space size='middle' direction='horizontal' align='center'>
            <Typography.Title level={xs ? 5 : 2}>Latest Announcements</Typography.Title>
            <Tooltip title='Go to Discourse'>
              <Button shape='circle'
                style={{ marginBottom: 6 }}
                icon={discourseIcon()}
                target='_blank'
                href='https://discourse.pollination.solutions/' />
            </Tooltip>
          </Space>
          <RecentTopics />
        </Col>
      </Row>
    </Skeleton>
  )
}

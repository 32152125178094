import { OrganizationRole, Permission } from '@pollination-solutions/pollination-sdk'
import { useAuth } from 'auth-context'
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'


export enum ResourceType {
  Application = 'application',
  License = 'license',
  Organization = 'organization',
  OrganizationMember = 'organization_member',
  OrganizationTeam = 'organization_team',
  Project = 'project',
  Quota = 'quota',
  Subscription = 'subscription',
  Repository = 'repository'
}

interface RoleContextProps {
  hasPermission: ({ resource, action }: { resource: ResourceType, action: Permission }) => boolean
  canRead: (resource: ResourceType, account?: string) => boolean
  canWrite: (resource: ResourceType, account?: string) => boolean
  canAdmin: (resource: ResourceType, account?: string) => boolean
}

const RoleContext = createContext<RoleContextProps | undefined>(undefined)

export const RolesProvider: React.FC = ({ children }) => {
  const location = useLocation()
  const { client, user } = useAuth()
  const [accountName, setAccountName] = useState<string | null>(null)
  const [organizationRoles, setOrganizationRoles] = useState<OrganizationRole[]>([])

  useEffect(() => {
    if (location.pathname === '/') {
      return setAccountName(null)
    }
    const pathParts = location.pathname.split('/')
    if (pathParts.length > 1) {
      setAccountName(pathParts[1])
    }
  }, [location.pathname])

  useEffect(() => {
    if (user) {
      client.user.getOrganizationRoles().then(({ data }) => {
        setOrganizationRoles(data)
      })
    }
  }, [user, client])


  const getAccountPermissions = useCallback((accountName: string) => {
    return organizationRoles.filter((role) => role.owner.name === accountName).map((role) => role.resources).pop() || {}
  }, [organizationRoles])

  const hasPermission = useCallback(({ resource, action, account }: { resource: ResourceType, action: Permission, account?: string }) => {
    // If the user is the owner of the account, they have all permissions
    const targetAccount = account || accountName
    if (!targetAccount) {
      return false
    }
    if (user && user.username === targetAccount) {
      return true
    }

    const accountPermissions = getAccountPermissions(targetAccount)
    return accountPermissions[resource] && accountPermissions[resource].includes(action)
  }, [getAccountPermissions, user, accountName])


  const canAdmin = useCallback((resource: ResourceType, account?: string) => {
    return hasPermission({ resource, action: Permission.Admin, account })
  }, [hasPermission])

  const canWrite = useCallback((resource: ResourceType, account?: string) => {
    return hasPermission({ resource, action: Permission.Write, account }) || canAdmin(resource, account)
  }, [hasPermission, canAdmin])

  const canRead = useCallback((resource: ResourceType, account?: string) => {
    return hasPermission({ resource, action: Permission.Read, account }) || canWrite(resource, account) || canAdmin(resource, account)
  }, [hasPermission, canWrite, canAdmin])

  return (
    <RoleContext.Provider value={{ hasPermission, canRead, canWrite, canAdmin }}>
      {children}
    </RoleContext.Provider>
  )
}

export const useRoles = (): RoleContextProps => {
  const context = useContext(RoleContext)
  if (context === undefined) {
    throw new Error('useRoles must be used within a RoleProvider')
  }
  return context
}
import { OrganizationMember, OrganizationRole, OrganizationRoleBinding } from '@pollination-solutions/pollination-sdk'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { notification } from 'antd'
import { useAuth } from 'auth-context'


interface useOrganizationMembersProps {
  organizationName: string
}

interface OrganizationMemberWithRoles extends OrganizationMember {
  roles: OrganizationRole[]
}

const useOrganizationMembers = (props: useOrganizationMembersProps) => {
  const { client } = useAuth()

  const [members, setMembers] = useState<OrganizationMember[]>([])
  const [roles, setRoles] = useState<OrganizationRole[]>([])
  const [roleBindings, setRoleBindings] = useState<OrganizationRoleBinding[]>([])
  const [loading, setLoading] = useState<boolean>(true)

  const fetchMembers = useCallback(() => {
    return client.orgs.getOrgMembers({ name: props.organizationName }).then(({ data }) => {
      setMembers(data.resources)
      setLoading(false)
    }
    ).catch((e) => {
      notification.error({ message: 'Error fetching members', description: e.message })
      setLoading(false)
    })
  }, [client, props.organizationName])

  const fetchOrganizationRoles = useCallback(() => {
    return client.orgs.getOrgRoles({ name: props.organizationName }).then(({ data }) => {
      setRoles(data)
    }).catch((e) => {
      notification.error({ message: 'Error fetching organization roles', description: e.message })
    })
  }, [client, props.organizationName])

  const fetchOrganizationRoleBindings = useCallback(() => {
    return client.orgs.getOrgRoleBindings({ name: props.organizationName }).then(({ data }) => {
      setRoleBindings(data)
    }).catch((e) => {
      notification.error({ message: 'Error fetching role bindings', description: e.message })
    })
  }, [client, props.organizationName])

  const membersWithRoles = useMemo(() => {
    return members.map((member) => {
      const roles = roleBindings.filter((binding) => binding.user.username === member.user.username)
        .map((binding) => binding.role)
      return { ...member, roles }
    }) as OrganizationMemberWithRoles[]
  }, [members, roleBindings])

  useEffect(() => {
    setLoading(true)
    Promise.all([
      fetchMembers(),
      fetchOrganizationRoleBindings(),
      fetchOrganizationRoles(),
    ]).then(() => {
      setLoading(false)
    })
  }, [fetchMembers, fetchOrganizationRoleBindings, fetchOrganizationRoles])

  const addMember = useCallback((username: string) => {
    return client.orgs.addOrganizationMember({
      name: props.organizationName,
      username,
    }).then(() => {
      fetchMembers()
    }).catch((e) => {
      notification.error({ message: 'Error adding member', description: e.message })
    })
  }, [client, fetchMembers, props.organizationName])

  const removeMember = useCallback((username: string) => {
    return client.orgs.deleteOrgMember({
      name: props.organizationName,
      username
    }).then(() => {
      fetchMembers()
    }).catch((e) => {
      notification.error({ message: 'Error removing member', description: e.message })
    })
  }, [client, fetchMembers, props.organizationName])

  const upsertMemberRole = useCallback((username: string, memberRoles: string[]) => {
    const invalidRoles = roles.filter((role) => !roles.includes(role))
    if (invalidRoles.length > 0) {
      notification.error({ message: 'Invalid role name', description: `Invalid roles: ${invalidRoles.join(', ')}` })
      return Promise.resolve()
    }
    return Promise.all(memberRoles.map((role) => {
      return client.orgs.upsertOrgRoleBinding({
        name: props.organizationName,
        username,
        roleName: role,
      })
    })).then(() => {
      fetchOrganizationRoleBindings()
    }).catch((e) => {
      notification.error({ message: 'Error updating member role', description: e.message })
    })
  }, [client, fetchOrganizationRoleBindings, props.organizationName, roles])

  const removeMemberRole = useCallback((username: string, role: string) => {
    return client.orgs.deleteOrgRoleBinding({
      name: props.organizationName,
      username,
      roleName: role,
    }).then(() => {
      fetchOrganizationRoleBindings()
    }).catch((e) => {
      notification.error({ message: 'Error removing member role', description: e.message })
    })
  }, [client, fetchOrganizationRoleBindings, props.organizationName])

  return { membersWithRoles, roles, loading, addMember, removeMember, upsertMemberRole, removeMemberRole }
}

export default useOrganizationMembers
import { AccountPublic, Team, TeamMember } from '@pollination-solutions/pollination-sdk'
import { notification } from 'antd'
import { useAuth } from 'auth-context'
import { useCallback, useEffect, useState } from 'react'
import slugify from 'slugify'

export interface TeamWithMembers extends Team {
  owner: string
  link: string
  members: TeamMember[]
}

interface CreateTeamPayload {
  name: string
  description?: string
}

const useOrganisationTeams = (account?: AccountPublic) => {
  const { client } = useAuth()

  const [teams, setTeams] = useState<TeamWithMembers[]>([])
  const [loading, setLoading] = useState<boolean>(true)

  const fetchTeams = useCallback(async (orgName: string) => {
    try {
      const teamsResponse = await client.teams.listOrgTeams({ orgName, perPage: 100 })

      const teamsWithMembers = teamsResponse.data.resources.map(async (team) => {
        const teamSlug = team.slug.split('/').pop() as string
        const membersResponse = await client.teams.getOrgTeamMembers({ orgName, teamSlug })
        return {
          ...team,
          owner: orgName,
          link: `/${orgName}/teams/${teamSlug}`,
          members: membersResponse.data.resources
        }
      })

      return Promise.all(teamsWithMembers)
    } catch (err) {
      notification.error({ message: 'Failed to fetch teams', description: err })
    }
    return Promise.resolve([])
  }, [client])

  const createTeam = useCallback(async (payload: CreateTeamPayload) => {
    if (!account) return
    try {
      const { data } = await client.teams.createTeam({ orgName: account.name, teamCreate: payload })
      const newTeam = {
        id: data.id,
        owner: account.name,
        link: `/${account.name}/teams/${slugify(payload.name, { lower: true })}`,
        name: payload.name,
        description: payload.description,
        slug: `${account.name}/${slugify(payload.name, { lower: true })}`,
        members: []
      }
      setTeams([...teams, newTeam])
      return newTeam as TeamWithMembers
    } catch (err) {
      notification.error({ message: 'Failed to create team' })
      throw err
    }
  }, [client, account, setTeams, teams])

  useEffect(() => {
    if (!account || account.account_type != 'org') return

    setLoading(true)
    fetchTeams(account.name)
      .then((teams) => {
        setTeams(teams)
      }
      )
      .finally(() => setLoading(false))
  }, [fetchTeams, account])

  return { teams, createTeam, loading }
}

export default useOrganisationTeams